'use client'

import { FC } from 'react'
import { FAQPageJsonLd } from 'next-seo'
import { Minus, Plus } from 'phosphor-react-sc'
import { Blocks, CurrentPage, Section as ISection } from '@/types/site.interface'
import { ButtonLink } from '@/types/site.interface'
import { Disclosure } from '@headlessui/react'
import BlockContent from '../blocks/block-content'
import Section from '../molecules/section'

const styles = {
  sectionParent: 'grid',
  headingContainer:
    'flex flex-col items-start py-3 md:py-6 px-5 md:px-10 text-left lg:prose-headings:text-left lg:prose-p:text-left bg-white transition-rounded rounded-xl',
  faqContainer: 'mx-auto max-h-4xl divide-y divide-gray-900/10',
  dlElement: 'mt-4 space-y-3 ',
  disclosureButton: 'grid md:grid-flow-col w-full items-start md:justify-between text-left text-gray-900',
  disclosureQuestion: 'py-3 md:py-6 px-5 md:px-10 text-xl md:text-3xl font-semibold leading-7 text-subheading',
  disclosureAnswer: 'pb-6 text-base prose-p:m-1 prose-p:leading-7 text-gray-600 max-w-none hyphens-auto',
  disclosureIcon: 'm-6 flex h-7 justify-self-end md:items-center',
}

interface FAQ {
  _key: string
  question: string
  answer: Blocks
}

interface JsonLdFAQ {
  questionName: string
  acceptedAnswerText: string
}

interface ContentFAQProps {
  faqs: FAQ[]
  section: ISection
  button?: ButtonLink
  jsonLdFAQ: JsonLdFAQ[]
  currentPage: CurrentPage
}

const ContentFAQ: FC<ContentFAQProps> = ({ faqs, section, currentPage, jsonLdFAQ = [] }) => {
  return (
    <Section
      element="c-content-faq"
      className={styles.sectionParent}
      contentClassName="w-full"
      headingClassName={styles.headingContainer}
      section={section}
    >
      <FAQPageJsonLd useAppDir id={currentPage.slug} mainEntity={jsonLdFAQ} />
      <div className={styles.faqContainer}>
        <dl className={styles.dlElement}>
          {faqs?.map((faq) => (
            <Disclosure as="div" key={faq?._key} className="bg-white transition-rounded rounded-xl" defaultOpen>
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className={styles.disclosureButton}>
                      <span className={styles.disclosureQuestion}>{faq?.question}</span>
                      {faq?.answer && (
                        <span className={styles.disclosureIcon}>
                          {open ? (
                            <Minus size={25} color="#D73451" strokeWidth={4} strokeLinecap="round" weight="bold" />
                          ) : (
                            <Plus size={25} color="#D73451" strokeWidth={4} strokeLinecap="round" weight="bold" />
                          )}
                        </span>
                      )}
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 sm:px-5">
                    <div className="md:px-4">
                      <BlockContent className={styles.disclosureAnswer} blocks={faq?.answer} />
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </Section>
  )
}
export default ContentFAQ
