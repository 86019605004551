import React from 'react'
import { cn } from '../../../utils/tailwind-helpers'
import { useCarousel } from './carousel'

const CarouselSteps = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const { selectedIndex, scrollTo, api } = useCarousel()
    const snapList = api?.scrollSnapList()
    if (!snapList || snapList?.length < 2) return null

    return (
      <div className={cn('w-full flex items-center justify-center mt-5', className)} ref={ref} {...props}>
        {snapList?.map((_, index) => (
          <button
            key={index}
            className={cn('h-4 w-4 rounded-full border-2 transition-all duration-300 border-primary', {
              'bg-primary w-12': index === selectedIndex,
            })}
            onClick={() => scrollTo(index)}
          />
        ))}
      </div>
    )
  },
)
CarouselSteps.displayName = 'CarouselSteps'
export default CarouselSteps
