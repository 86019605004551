'use client'

import * as React from 'react'
import { cn } from '../../../utils/tailwind-helpers'
import { useCarousel } from './carousel'
import CarouselSteps from './steps'

interface CarouselContentProps extends React.HTMLAttributes<HTMLDivElement> {
  stepsEnabled?: boolean
}

export const CarouselContent = React.forwardRef<HTMLDivElement, CarouselContentProps>(
  ({ className, stepsEnabled, ...props }, ref) => {
    const { carouselRef, orientation } = useCarousel()
    return (
      <div ref={carouselRef} className="overflow-hidden">
        <div
          ref={ref}
          className={cn('flex', orientation === 'horizontal' ? '-ml-4' : '-mt-4 flex-col', className)}
          {...props}
        />
        {stepsEnabled && <CarouselSteps className="relative mb-0 flex gap-4" />}
      </div>
    )
  },
)
CarouselContent.displayName = 'CarouselContent'

export const CarouselItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const { orientation } = useCarousel()

    return (
      <div
        ref={ref}
        role="group"
        aria-roledescription="slide"
        className={cn('min-w-0 shrink-0 grow-0 basis-full', orientation === 'horizontal' ? 'pl-4' : 'pt-4', className)}
        {...props}
      />
    )
  },
)
CarouselItem.displayName = 'CarouselItem'
