'use client'
import { FC, Fragment, useState } from 'react'
import cx from 'clsx'
import { Play } from 'phosphor-react'
import { ImageAsset } from '@/types/site.interface'
import { CarouselContent, CarouselItem } from '@ignition/library/components/molecules/carousel'
import ImageElement from '../image'
import { LightBoxPlayer } from '../video-player'
import LightBox from './light-box'

interface Image extends ImageAsset {
  videoUrl?: string
}

export interface GalleryCarouselProps {
  images?: Image[]
  inline?: boolean
}

const aspectRatio = (customRatio?: string, imageRatio?: number): number => {
  switch (customRatio) {
    case '1:1':
      return 1 / 1
    case '2:1':
      return 2 / 1
    case '3:1':
      return 3 / 1
    case '5:7':
      return 5 / 7
    case '4:6':
      return 4 / 6
    case '16:9':
      return 16 / 9
    default:
      return imageRatio || 1
  }
}

const GalleryCarousel: FC<GalleryCarouselProps> = ({ images, inline }) => {
  const [openImage, setOpenImage] = useState(false)
  const [index, setIndex] = useState(0)
  const [url, setUrl] = useState<string>('')
  const [openPlayer, setOpenPlayed] = useState<boolean>(false)

  const handleClose = () => {
    setOpenPlayed(!openPlayer)
    setUrl('')
  }
  const openLightBox = (index: number, item: Image) => {
    if (item.videoUrl) {
      setUrl(item.videoUrl)
      setOpenPlayed(!openPlayer)
      return
    } else {
      setIndex(index)
      setOpenImage(!openImage)
    }
  }

  if (!images?.length) return null

  return (
    <Fragment>
      <CarouselContent stepsEnabled>
        {images?.map((item, i) => {
          const isInline = inline ? 2 : 1
          const imageRatio = aspectRatio(item.customRatio, item?.aspectRatio)
          const width = images.length <= 1 ? 100 : Math.floor((imageRatio / isInline) * 100)

          return (
            <CarouselItem
              key={i}
              className={cx(`h-auto flex relative w-full cursor-pointer max-h-[600px] justify-center`)}
              style={{
                flexBasis: `${width}%`,
                aspectRatio: imageRatio,
              }}
              onClick={() => openLightBox(i, item)}
            >
              <ImageElement
                className={cx('w-full h-auto relative')}
                fill
                image={item}
                useFallbackImage
                imgClassName={cx('rounded-oval-shaped w-full h-auto')}
              />
              {item?.videoUrl && (
                <div className="absolute z-[2] self-center">
                  <button
                    className="absolute w-24 h-24 flex items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-300 rounded-full p-7"
                    onClick={() => setOpenPlayed(true)}
                  >
                    <Play size={42} weight="fill" className="text-white" />
                  </button>
                </div>
              )}
            </CarouselItem>
          )
        })}
      </CarouselContent>
      {openImage && <LightBox open={openImage} close={() => setOpenImage(false)} slides={images} index={index} />}
      {openPlayer && <LightBoxPlayer videoUrl={url} title="" isPlaying={openPlayer} closeVideo={handleClose} />}
    </Fragment>
  )
}
export default GalleryCarousel
